import Backbone from 'backbone';
import { Zipcode } from './models';

export const Zipcodes = Backbone.Collection.extend({
  model: Zipcode,
  initialize: function(options) {
    this.upin = options.upin;
    this.mode = options.mode;
    this.invoker = options.invoker;
    this.fsa_id = options.fsa_id;
    this.token = options.token;
    this.primary_threshold = options.primary_threshold || 50;
    this.secondary_threshold = options.secondary_threshold || 75;
    this.include_postcodes = options.include_postcodes || false;
  },
  url: function() {
    if (this.mode === 'create' || arguments[0] === 'rebuild') {
      return `/account/fsa/marketshare.json?upin=${this.upin}&primary=${this.primary_threshold}&secondary=${this.secondary_threshold}&include_postcodes=${this.include_postcodes}`;
    } else if (this.mode === 'edit') {
      return `/account/fsa/view/${this.fsa_id}/${this.token}.json`;
    }

    return false;
  },
  parse: function(response) {
    const { primary_zipcodes, secondary_zipcodes, features, bbox } = response;
    let collection = [];

    this.invoker.primary_zipcodes = primary_zipcodes;
    this.invoker.secondary_zipcodes = secondary_zipcodes;
    this.invoker.bbox = bbox;

    // The keys in features are the zipcodes
    for (let zipcode in features) {
      if (Object.prototype.hasOwnProperty.call(features, zipcode)) {
        let feature = features[zipcode];
        if (~primary_zipcodes.indexOf(zipcode)) {
          feature.collection = 'primary';
        } else if (~secondary_zipcodes.indexOf(zipcode)) {
          feature.collection = 'secondary';
        }

        collection.push(new Zipcode(feature));
      }
    }

    return collection;
  },

  comparator: function(zipcode) {
    return -zipcode.get('cases').replace(/[^\d.\- ]/g, '');
  },

  rebuild: function(callback) {
    this.fetch({ url: this.url('rebuild'), success: callback });
  },

  hasPrimaryZipCodes: function() {
    return this.models.some(zip => zip.get('collection') === 'primary');
  },

  getServiceAreas: function() {
    return this.models.map(model => {
      return {
        id: model.get('zipcode'),
        collection: model.get('collection')
      };
    });
  }
});
