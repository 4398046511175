const templateContainer = $('#product-line-definition-detail-container');

if (templateContainer.length) {
  $('#pld_name').on('click', function() {
    $('#pld_name').hide();
    $('#pld_name_form')
      .addClass('active')
      .find('#id_name')
      .focus();
  });
  $('#pld_name_form').on('submit', function() {
    $('#pld_name_form').removeClass('active');
    $('#pld_name').show();
  });
  if ($('.form-group').hasClass('has-error')) {
    $('#pld_name').hide();
    $('#pld_name_form')
      .addClass('active')
      .find('#id_name')
      .focus();
  }
}
