import { View } from 'backbone';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';

let StrataBaseMap = View.extend({
  // Containing element
  mapContainer: 'map2',
  // base options
  mapOptions: {
    zoom: 10,
    maxZoom: 12,
    attributionControl: false
  },
  // New Mapbox vector tiles
  mapboxStyles: {
    light: 'mapbox://styles/stratasan/cjs39iilq2i7t1fkgiouxqxzy',
    base: 'mapbox://styles/stratasan/cjs39b35j0wmx1ft7tukdo192',
    streets: 'mapbox://styles/stratasan/cjsxta6g06jg41fo0thv8g652'
  },
  // Styles for mapbox layers
  styles: {
    'service-areas': {
      primary: 'rgba(57, 109, 162, 0.8)',
      secondary: 'rgba(137, 174, 77, 0.8)',
      other: 'rgba(0, 0, 0, 0)'
    },
    'search-ring': {
      type: 'line',
      paint: {
        'line-color': '#000',
        'line-width': 3,
        'line-opacity': 0.25
      }
    },
    hospitals: {
      type: 'symbol',
      layout: {
        'icon-image': {
          property: 'collection',
          type: 'categorical',
          stops: [
            ['primary', 'marker-primary-20'],
            ['other', 'marker-other-20']
          ]
        },
        'icon-size': 0.5,
        'icon-allow-overlap': true,
        'icon-offset': [0, -6.5],
        'symbol-z-order': 'source'
      },
      paint: {
        'icon-opacity': {
          property: 'collection',
          type: 'categorical',
          stops: [
            ['primary', 1],
            ['other', 0.75]
          ]
        }
      }
    }
  },

  initMap: function(accessToken, options) {
    /*
      Extended views should use this to create the map.
      Returns map for chaining
    */
    mapboxgl.accessToken = accessToken;

    const enableNavigation =
      'interactive' in options ? options.interactive : true;

    const mapOptions = Object.assign(
      this.mapOptions,
      {
        container: this.mapContainer,
        style: this.mapboxStyles.base
      },
      options
    );
    this.map = new mapboxgl.Map(mapOptions);

    if (enableNavigation) {
      this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    }
    this.map.scrollZoom.disable();

    return this.map;
  },

  setupMap: function() {
    /*
      Extenders should add specific map interactions here.
    */
  },

  shouldDisplayControls: function() {
    return true;
  },

  addOrUpdateLayer: function(layerId, data, beforeLayer) {
    if (this.map.getLayer(layerId)) {
      this.map.getSource(layerId).setData(data);
    } else {
      this.map.addSource(layerId, {
        type: 'geojson',
        data: data
      });

      const layerDefinition = Object.assign(
        {
          id: layerId,
          source: layerId
        },
        this.styles[layerId]
      );

      if (beforeLayer) {
        this.map.addLayer(layerDefinition, beforeLayer);
      } else {
        this.map.addLayer(layerDefinition);
      }
    }
  },

  displayPopup: function(feature, popup, text) {
    const coordinates = feature.geometry.coordinates.slice();
    // Populate the popup and set its coordinates
    // based on the feature found.
    popup
      .setLngLat(coordinates)
      .setHTML(text)
      .addTo(this.map);
  }
});

export default StrataBaseMap;
