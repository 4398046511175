import request from 'superagent';

/**
 * Health API client.
 * Used for making requests to the backend server.
 */
export default class HealthClient {
  baseUrl: string;

  constructor(baseUrl = '/api') {
    this.baseUrl = baseUrl;
  }

  /**
   * Send a GET request.
   *
   * @param  {String}  uri         Endpoint URI to hit.
   * @param  {Object}  options     Query params to pass along with the request.
   * @param  {Boolean} abortActive Whether to abort previous request or not.
   * @return {Promise}             Request response.
   */
  get(uri, options: any = {}) {
    return this._sendRequest((resolve, reject) => {
      let url = this._getUrl(uri);
      if (options.queryString) {
        url += '?' + options.queryString;
        delete options.queryString;
      }

      return request
        .get(url)
        .set('Accept', 'application/json')
        .query(options)
        .end((error, response) => {
          if (error) {
            reject(response);
          }

          resolve(response?.body);
        });
    });
  }

  /**
   * Send a POST request.
   *
   * @return {void}
   */
  post(...args: [any, any]) {
    return this._sendJSONRequest('POST', ...args);
  }

  /**
   * Send a PUT request.
   *
   * @return {void}
   */
  put(...args: [any, any]) {
    return this._sendJSONRequest('PUT', ...args);
  }

  /**
   * Send a PATCH request.
   *
   * @return {void}
   */
  patch(...args: [any, any]) {
    return this._sendJSONRequest('PATCH', ...args);
  }

  /**
   * Send a DELETE request.
   *
   * @return {void}
   */
  delete(...args: [any, any]) {
    return this._sendJSONRequest('DELETE', ...args);
  }

  /**
   * Get a cookie.
   *
   * @param  {String} name Name of the cookie to retrieve.
   * @return {String}      Cookie value.
   */
  getCookie(name) {
    const docCookie = document.cookie;
    let value: any = null;

    if (docCookie && docCookie !== '') {
      const cookies = docCookie.split(';');

      cookies.forEach(c => {
        const cookie = c.trim();

        if (cookie.substring(0, name.length + 1) === name + '=') {
          value = decodeURIComponent(cookie.substring(name.length + 1));
          return;
        }
      });
    }

    return value;
  }

  /**
   * Wrap request in a promise.
   *
   * @param  {Function} reqCall     Request to make.
   * @param  {Boolean}  abortActive Whether to abort previous request or not.
   * @return {Promise}              Request response.
   * @private
   */
  _sendRequest(reqCall) {
    return new Promise((resolve, reject) => {
      reqCall(resolve, reject);
    });
  }

  /**
   * Send a JSON-type request (POST, PUT, PATCH) request.
   *
   * @param  {String}  type        Type of request to make.
   * @param  {String}  uri         Endpoint URI to hit.
   * @param  {Object}  options     Additional options to pass along with the request.
   * @param  {Boolean} abortActive Whether to abort previous request or not.
   * @return {Promise}             Request response.
   * @private
   */
  _sendJSONRequest(type, uri, options) {
    return this._sendRequest((resolve, reject) => {
      return request(type, this._getUrl(uri))
        .set('Accept', 'application/json')
        .set('X-CSRFToken', this.getCookie('csrftoken'))
        .send(options)
        .end((error, response) => {
          if (response && response.body && !error) {
            resolve(response.body);
          } else {
            reject(response);
          }
        });
    });
  }

  /**
   * Get the full URL string.
   *
   * @param  {String} uri URI to append to base URL.
   * @return {String} Full URL string.
   * @private
   */
  _getUrl(uri) {
    return `${this.baseUrl}/${uri}/`;
  }
}

export const PathwaysClient = new HealthClient('/api/pathways');
