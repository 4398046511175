// docs for browser NumberFormat: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#specifications

export function formatToPercentageWholeNumber(number) {
  //Note: Includes the % sign in the format string. i.e. '50%'
  if (number) {
    return new Intl.NumberFormat('en-US', { style: 'percent' }).format(number);
  }
  return new Intl.NumberFormat('en-US', { style: 'percent' }).format(0);
}

export function formatNumberToCurrency(number) {
  if (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: 'USD'
    }).format(number);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'USD'
  }).format(0);
}

export function formatNumberToHaveCommas(number) {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0
  }).format(number);
}

export function formatToPercentageDecimal(number) {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2
  }).format(number);
}

export function formatToTitleCase(str: string) {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}
