import nod from 'nod-validate';
import Backbone from 'backbone';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.min';
import StrataFacilityMap from '../maps/facility';
import StrataMapSearchControl from '../controls/SearchControl';
import { Zipcodes } from './collections';
import { Zipcode } from './models';
import numberFormat from '../../numberFormat';
import HealthClient from '../../../clients/HealthClient';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/sortable';

const validator = nod();
const accountClient = new HealthClient('/account');
const mappingClient = new HealthClient('/mapping');

let Stratasan = {
  Models: {},
  Collections: {},
  Views: {},
  App: {}
};

Stratasan.Views.ZipcodeView = Backbone.View.extend({
  tagName: 'tr',
  initialize: function(options) {
    this.parent = options.parent;
  },
  render: function() {
    this.$el.attr('id', 'zip_row_' + this.model.get('zipcode'));
    this.$el.attr('data-cid', this.model.cid);
    this.$el.addClass(this.model.get('collection'));
    this.$el.addClass('zip_row');
    this.$el.data('zipcode', this.model.get('zipcode'));
    this.$el.data('cid', this.model.cid);
    let model = this.model.toJSON();

    let zipcodeDisplay = model.zipcode;
    if (model.is_postcode) {
      zipcodeDisplay = zipcodeDisplay + ' (PO)';
    }

    this.$el.html(`
      <td class="zipcode"><i class="icon-circle"></i>
        <a href="#" class="view-zip">${zipcodeDisplay}</a>
        <img src="/static/img/ajax-loader-small.gif" alt="loading..."/ class="hide loading">
      </td>
      <td>${model.city}, ${model.state}</td>
      <td>${model.cases}</td>
      <td>${model.share_of_patients || 'N/A'}%</td>
      <td>${model.csum || 'N/A'}%</td>
      <td>${model.market_share || 'N/A'}%</td>
      <td>${model.population}</td>
      <td><a href="#" data-zipcode="${zipcodeDisplay}" class="btn btn-default btn-xs delete-btn"><i class="fa fa-times"></i></a></td>
    `);

    return this;
  },

  events: {
    'click .delete-btn': 'delete',
    'click .view-zip': 'view'
  },

  delete: function(event) {
    let postal_codes = this.model.attributes.postal_codes;
    let parent_collection = this.model.attributes.collection;
    let self = this;
    // remove children first
    if (postal_codes.length) {
      postal_codes.forEach(function(po) {
        // Step 1 find tr element
        let $po_row = $('#zip_row_' + po);
        // Step 2 Destroy the child model
        let zipcode_obj = self.model.collection.findWhere({
          zipcode: po
        });
        if (typeof zipcode_obj !== 'undefined') {
          if (zipcode_obj.attributes.collection === parent_collection) {
            // Verifying that we're deleting only the children in
            // the same service area collection.
            zipcode_obj.destroy();
          }
        }
        // Step 3 Remove the tr element
        $po_row.remove();
      });
    }
    // remove parent
    this.model.destroy();
    this.remove();
    // Update the collection
    this.parent.updateCumulative();
    this.parent.updateServiceAreaFeatures('zipcodes');
    event.preventDefault();
  },

  view: function(event) {
    if (!this.model.attributes.is_postcode) {
      let parent = this.parent;
      let map = this.parent.map;
      let zipcode = this.model.get('zipcode');

      const features = map.querySourceFeatures('composite', {
        sourceLayer: map.getLayer('zipcodes').sourceLayer,
        filter: ['==', 'id', zipcode]
      });

      if (features.length > 0) {
        const {
          properties: { city, state }
        } = features[0];
        // Get zipcode bounds from zipcodes/bbox endpoint to
        // avoid vector tile clipping issues
        mappingClient
          .post('zipcodes/bbox', {
            zipcodes: [zipcode]
          })
          .then(response => {
            if ('bbox' in response) {
              map.fitBounds(response.bbox, { padding: 20 });
              this.parent.infoControl.updateInfo(
                `${zipcode} (${city}, ${state})`
              );
            }
          });
      } else {
        parent
          .geocode(zipcode)
          .then(response => response.json())
          .then(geojson => {
            if ('features' in geojson) {
              const {
                place_name,
                geometry: { coordinates }
              } = geojson.features[0];
              // Mapbox geocode returns a place_name in the format "City, State zipcode, United States"
              // We need to rearrange it to "zipcode (City, State)"
              const place = place_name
                .slice(0, place_name.lastIndexOf(','))
                .replace(',', '')
                .split(' ');
              map.flyTo({
                center: coordinates,
                zoom: 12,
                speed: 0.7
              });
              this.parent.infoControl.updateInfo(
                `${place[2]} (${place[0]}, ${place[1]})`
              );
            }
          });
      }
    }
    event.preventDefault();
  }
});

Stratasan.Views.MapView = StrataFacilityMap.extend({
  $primaryEl: $('#primary_service_areas'),
  $secondaryEl: $('#secondary_service_areas'),

  service_areas: {
    primary: [],
    secondary: []
  },

  initialize: function(options) {
    let view = this;
    view.upin = options.upin;
    view.rid = options.rid || false;
    view.mode = options.mode || 'create';
    view.fsa_id = options.fsa_id;
    view.token = options.token;
    view.accessToken = options.accessToken;
    view.loading = true;
    view.collection = new Zipcodes({
      upin: options.upin,
      invoker: view,
      mode: view.mode,
      fsa_id: view.fsa_id,
      token: view.token,
      primary_threshold: options.primary_threshold || 50,
      secondary_threshold: options.secondary_threshold || 75,
      include_postcodes: true
    });
    $('#map2_overlay').fadeIn();
    view.collection.fetch({
      success: function() {
        $('#loading_indicator').hide();
        $('#collapseOne').collapse('hide');
        $('#collapseTwo').collapse('show');
        $('#ssa_map_step1').addClass('disabled');
        $('#ssa_map_step2').removeClass('disabled');
        view.form = new Stratasan.Views.FormView({ parent: view });
        view.getHospital().then(data => {
          view.hospital = data.facility;
          view.getHospitalLngLat(lngLat => {
            view.initMap(view.accessToken, { center: lngLat });
            view.map.on('load', () => {
              view.setupMap();
            });
          });
        });
      },
      silent: true
    });

    view.collection.on('add', function() {
      view.updateServiceAreaFeatures('zipcodes');
      view.updateCumulative();
    });

    view.collection.on('add remove change', function() {
      view.form.setSubmittableState();
    });

    view.collection.on('change:collection', function() {
      view.updateServiceAreaFeatures('zipcodes');
      view.updateCumulative();
    });
  },

  render: function() {
    let view = this;
    view.$primaryEl.html('');
    view.$secondaryEl.html('');
    let totals = {
      primary: {
        cases: 0,
        share_of_patients: 0,
        market_share: 0,
        population: 0
      },
      secondary: {
        cases: 0,
        share_of_patients: 0,
        market_share: 0,
        population: 0
      }
    };

    $('#hospital_name').html(
      '<i class="icon-h-sign icon-white"></i> ' + view.hospital.name
    );
    $('#hospital_address').html(
      view.hospital.address +
        ' ' +
        view.hospital.city +
        ', ' +
        view.hospital.state +
        ' ' +
        view.hospital.zipcode +
        ' <span title="CMS ID">(' +
        view.hospital.upin +
        ')</span>'
    );

    view.collection.models.forEach(function(model) {
      let collection = model.get('collection');

      if (collection === 'primary' || collection === 'secondary') {
        if (parseInt(model.get('cases'), 10)) {
          totals[collection].cases += parseInt(
            model.get('cases').replace(',', ''),
            10
          );
        }
        if (parseFloat(model.get('share_of_patients'))) {
          totals[collection].share_of_patients += model.get(
            'share_of_patients'
          );
        }

        if (parseFloat(model.get('market_share'))) {
          totals[collection].market_share += model.get('market_share');
        }

        if (parseInt(model.get('population'), 10)) {
          totals[collection].population += parseInt(
            model.get('population').replace(',', ''),
            10
          );
        }
      }
      view.renderZipcode(model);
    });

    // eslint-disable-next-line guard-for-in
    for (let collection in totals) {
      // eslint-disable-next-line guard-for-in
      for (let total in totals[collection]) {
        let new_total;

        if (total !== 'cases' && total !== 'population') {
          new_total = numberFormat(totals[collection][total], 1, '.', ',');
        } else {
          new_total = numberFormat(totals[collection][total], 0, '.', ',');
        }

        totals[collection][total] = new_total;
      }
    }

    $('#primary_totals').html(`
      <tr>
        <td><i class="icon-bar-chart"></i></td>
        <td><strong>Totals</strong></td>
        <td>${totals.primary.cases}</td>
        <td>${totals.primary.share_of_patients}%</td>
        <td></td>
        <td></td>
        <td>${totals.primary.population}</td>
        <td></td>
      </tr>
    `);

    $('#secondary_totals').html(`
      <tr>
        <td><i class="icon-bar-chart"></i></td>
        <td><strong>Totals</strong></td>
        <td>${totals.secondary.cases}</td>
        <td>${totals.secondary.share_of_patients}%</td>
        <td></td>
        <td></td>
        <td>${totals.secondary.population}</td>
        <td></td>
      </tr>
    `);

    if (!view.$primaryEl.children('tr').length) {
      view.$primaryEl.html(`
        <td colspan="9">
          <div class="drop-placeholder">
          <div class="alert-danger well">
          <p><i class="fa fa-warning"></i> Primary Service Areas <strong>must</strong> have at least one ZIP code</p>
          <p>Drag and drop or select a ZIP Code from the map to add new items to the Primary Service Areas.</p>
          </div>
          </div>
        </td>
      `);
      $('#primary_totals').fadeOut();
      $('#primary_head .table-head').fadeOut();
    } else {
      $('#primary_totals').fadeIn();
      $('#primary_head .table-head').fadeIn();
    }

    if (!view.$secondaryEl.children('tr').length) {
      view.$secondaryEl.html(`
        <td colspan="9">
          <div class="drop-placeholder">
          <div class="alert-info well">
          <p>Drag and drop or select a ZIP Code from the map to add new items to the Secondary Service Areas.</p>
          </div>
          </div>
        </td>
      `);
      $('#secondary_totals').fadeOut();
      $('#secondary_head .table-head').fadeOut();
    } else {
      $('#secondary_totals').fadeIn();
      $('#secondary_head .table-head').fadeIn();
    }
  },

  renderZipcode: function(model) {
    let zipcodeView = new Stratasan.Views.ZipcodeView({
      parent: this,
      model: model
    });

    if (model.get('collection') === 'primary') {
      this.$primaryEl.append(zipcodeView.render().el);
    } else if (model.get('collection') === 'secondary') {
      this.$secondaryEl.append(zipcodeView.render().el);
    }
  },

  zipClick: function(zip, focus) {
    let view = this;

    if (!view.loading) {
      view.loading = true;
      $('#map2_overlay').fadeIn();
      let zipCollection = view.collection.findWhere({ zipcode: zip });

      if (zipCollection) {
        zipCollection.updateCollection();
        view.loading = false;
        $('#map2_overlay').fadeOut();

        if (focus) {
          $(`#zip_row_${zip} .view-zip`).trigger('click');
        }
      } else {
        accountClient
          .post('fsa/new/feature', {
            upin: view.upin,
            zipcode: zip
          })
          .then(response => {
            if (response && Array.isArray(response)) {
              response.forEach(function(result) {
                let zipcodeExists = Boolean(
                  view.collection.findWhere({ zipcode: result.zipcode })
                );
                if (zipcodeExists) {
                  $('#map2_overlay').fadeOut();
                  view.loading = false;
                }

                if (result.zipcode) {
                  const {
                    zipcode,
                    cases,
                    city,
                    state,
                    feature,
                    market_share,
                    share_of_patients,
                    total_cases,
                    population,
                    postal_codes,
                    is_postcode
                  } = result;

                  let zipModel = new Zipcode({
                    collection: 'primary',
                    cases,
                    city,
                    state,
                    feature,
                    market_share,
                    share_of_patients,
                    total_cases,
                    zipcode,
                    population,
                    postal_codes,
                    is_postcode
                  });

                  view.collection.add(zipModel);

                  if (focus) {
                    $(`#zip_row_${zipcode} .view-zip`).trigger('click');
                  }

                  //$('#zipcode-search-control input').attr(
                  //  'placeholder',
                  //  'ZIP Search'
                  //);
                } else if (result.error) {
                  $('#zipcode-search-control input').attr(
                    'placeholder',
                    result.message
                  );
                  $('#zipcode-search-control').toggleClass('active');
                }

                view.loading = false;
                $('#map2_overlay').fadeOut();
              });
            }
          });
      }
    }

    return;
  },

  setupMap: function() {
    let view = this;
    // Instantiate our controls

    view.control = new StrataMapSearchControl({ parent: view });
    view.mapboxGeocoder = new MapboxGeocoder({
      accessToken: view.accessToken,
      flyTo: false,
      countries: 'us',
      placeholder: 'Search for a ZIP code',
      trackProximity: true,
      types: 'postcode',
      marker: {
        color: 'orange'
      },
      mapboxgl: mapboxgl
    });

    view.map.addControl(view.mapboxGeocoder, 'bottom-left');
    view.setupControls();
    view.setupFeatures();
    view.control.searchUpdate();
    view.setupMapInteractions();

    $('#primary_service_areas, #secondary_service_areas')
      .droppable({
        hoverClass: 'ui-state-hover',
        drop: function(event, ui) {
          let item = $(ui.draggable.context);
          let collection_name = $(this).data('collection');
          let cid = item.data('cid');
          let zip = view.collection.get(cid);

          zip.set('collection', collection_name);
          // Set child post codes to parents collection
          let postal_codes = zip.attributes.postal_codes;
          let zipcode_obj;
          if (postal_codes.length) {
            postal_codes.forEach(function(po) {
              zipcode_obj = zip.collection.findWhere({ zipcode: po });
              if (typeof zipcode_obj !== 'undefined') {
                zipcode_obj.set('collection', collection_name);
              }
            });
          }
        }
      })
      .sortable({
        //items: "li:not(.placeholder)",
        connectWith: '.connectedSortable',
        placeholder: 'ui-placeholder',
        tolerance: 'pointer',
        items: '.zip_row',
        start: function(event, ui) {
          let collection_name = $(ui.item)
            .parent()
            .data('collection');
          $(ui.item).data('parent', collection_name);
        },

        stop: function() {
          view.updateCumulative();
        }
      });
    view.updateCumulative();
    view.loading = false;
    $('#map2_overlay').fadeOut();
  },

  getHospital: function() {
    let view = this;
    return $.get(`/mapping/facility_detail/${view.upin}`);
  },

  getSearchLngLat: function(cb) {
    return this.getHospitalLngLat(cb);
  },

  updateCumulative: function() {
    let csum = 0;
    const primary_collection = this.collection.where({ collection: 'primary' });
    const secondary_collection = this.collection.where({
      collection: 'secondary'
    });

    primary_collection.forEach(model => {
      csum += model.get('share_of_patients');
      model.set({ csum: numberFormat(csum, 1, '.', ',') });
    });

    secondary_collection.forEach(model => {
      csum += model.get('share_of_patients');
      model.set({ csum: numberFormat(csum, 1, '.', ',') });
    });

    this.render();
  },

  setupFeatures: function() {
    let view = this;
    view.updateServiceAreaFeatures('zipcodes');
    view.map.fitBounds(view.bbox, { padding: 10, duration: 0 });
  },

  updateServiceAreaFeatures(layerId) {
    const serviceAreas = this.collection.getServiceAreas();
    let expression = ['match', ['get', 'id']];
    serviceAreas.forEach(serviceArea => {
      expression.push(
        serviceArea.id,
        this.styles['service-areas'][serviceArea.collection]
      );
    });

    // Set a default value for remaining features
    expression.push('rgba(0, 0, 0, 0)');

    this.map.setPaintProperty(layerId, 'fill-color', expression);
  },

  setupMapInteractions() {
    this.mapboxGeocoder.on('result', event => {
      this.zipClick(event.result.text);
    });

    this.map.on('click', 'zipcodes', event => {
      if (event.features.length > 0) {
        const zipcode = event.features[0].properties.id;
        this.zipClick(zipcode);
      }
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 10
    });

    this.map.on('mousemove', event => {
      const layers = this.map.getStyle().layers.map(layer => layer.id);
      if (layers.indexOf('hospitals') < 0 || layers.indexOf('zipcodes') < 0) {
        return;
      }
      const features = this.map.queryRenderedFeatures(event.point, {
        layers: ['hospitals', 'zipcodes']
      });

      if (features.length > 0) {
        this.map.getCanvas().style.cursor = 'pointer';

        const seenFeatures = {
          zipcodes: [],
          hospitals: []
        };
        features.forEach(feature => {
          seenFeatures[feature.layer.id].push(feature);
        });

        if (seenFeatures.zipcodes.length > 0) {
          const {
            id: zipcode,
            city,
            state
          } = seenFeatures.zipcodes[0].properties;
          const zipInfo = `${zipcode} (${city}, ${state})`;
          this.infoControl.updateInfo(zipInfo);
        } else {
          this.infoControl.clearInfo();
        }

        if (seenFeatures.hospitals.length > 0) {
          const hospital = seenFeatures.hospitals[0];
          this.displayPopup(hospital, popup, hospital.properties.name);
        } else {
          popup.remove();
        }
      } else {
        this.map.getCanvas().style.cursor = '';
        this.infoControl.clearInfo();
        popup.remove();
      }
    });
  }
});

Stratasan.Views.FormView = Backbone.View.extend({
  initialize: function(options) {
    this.parent = options.parent;

    validator.configure({
      silentSubmit: true,
      submitBtnSelector: '.btn'
    });
    validator.add({
      selector: '#id_name',
      validate: 'presence',
      errorMessage: 'Please enter a service area name.'
    });
  },

  el: $('#save_form'),

  events: {
    'click .save': 'save'
  },

  setSubmittableState: function() {
    if (this.parent.collection.hasPrimaryZipCodes()) {
      this.$el.find('button').removeAttr('disabled');
    } else {
      this.$el.find('button').attr('disabled', 'disabled');
    }
  },

  save: function(event) {
    event.preventDefault();

    // Cache jQuery objects
    let $id_name = $('#id_name');
    let $form = this.$el;
    let $target = $(event.target);

    let data = {
      action: $target.data('action'),
      rid: this.parent.rid || false,
      primary: [],
      secondary: [],
      upin: this.parent.upin,
      fsa_id: this.parent.fsa_id,
      token: this.parent.token,
      name: $id_name.val()
    };

    // Grab bare-minimum of map config
    let center = this.parent.map.getCenter();
    data.map_center_lat = center.lat;
    data.map_center_lng = center.lng;
    data.map_zoom_level = this.parent.map.getZoom();
    let hospital_lnglat = this.parent.getHospitalLngLat();
    data.map_hospital_lng = hospital_lnglat[0];
    data.map_hospital_lat = hospital_lnglat[1];

    if (this.parent.control.searchIsActivated()) {
      data.map_search_type = this.parent.control.getSearchType();
      if (data.map_search_type === 'radius') {
        data.map_search_radius = parseInt(
          this.parent.control.getSearchRadius(),
          10
        );

        data.map_search_lng = hospital_lnglat[0];
        data.map_search_lat = hospital_lnglat[1];
      }
      // Stuff data with other search options here
    }

    this.parent.collection.forEach(function(zip) {
      if (zip.get('collection') !== 'other') {
        data[zip.get('collection')].push(zip.get('zipcode'));
      }
    });

    let save = function() {
      accountClient.post('fsa/new/save', data).then(response => {
        if (response.success && response.redirect) {
          window.location.replace(response.return_url);
          return;
        }
      });

      $id_name.addClass('ui-autocomplete-loading');

      $form.find('.help-block').remove();
    };

    let displayMessage = function() {
      if (!$form.find('.service-area-message').length) {
        $id_name.after(
          '<div class="small help-block service-area-message">' +
            'A service area with the same name and facility ' +
            'already exists. Please choose a different name.' +
            '</div>'
        );

        $id_name.on('keyup', function() {
          if ($id_name.val() === '') {
            $form.find('.service-area-message').remove();
          }
        });
      }
    };

    accountClient
      .post('api/serviceareagroup/validate', {
        upin: this.parent.upin,
        name: $id_name.val(),
        fsa_id: this.parent.fsa_id || null
      })
      .then(response => (response.existing ? displayMessage() : save()));
  }
});

export const MapView = Stratasan.Views.MapView;
