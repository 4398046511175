import React, { useEffect, useState, useRef } from 'react';
import { formatNumberToHaveCommas } from './libs/formatters';
import { AgGridReact } from 'ag-grid-react';
import { OpensearchGenericTypeahead } from './OpensearchGenericTypeahead';
import {
  ColDef,
  ValueFormatterParams,
  SizeColumnsToContentStrategy
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { accountApi, handleServiceUnavailable } from './libs/api';
import { ApcdCoverageMetricsResponse, ApcdCoverageMetrics } from './libs/types';
import { useQuery } from 'react-query';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const ApcdTypeOneTwoCoverageMetrics: React.FC = () => {
  const gridApiRef = useRef<AgGridReact>(null);

  const [selectedState, setSelectedState] = useState({ value: null });
  const [selectedProviderCategory, setSelectedProviderCategory] = useState({
    value: 'Allopathic & Osteopathic Physicians',
    label: 'Allopathic & Osteopathic Physicians'
  });
  const [rowData, setRowData] = useState<ApcdCoverageMetrics[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const handleStateChange = selectedOption => {
    setSelectedState(selectedOption);
  };

  const handleProviderCategoryChange = selectedOption => {
    setSelectedProviderCategory(selectedOption);
  };
  const { data, refetch } = useGetApcdCoverageMetricsByState([
    { column: 'state', value: selectedState?.value },
    {
      column: 'main_individual_provider_category',
      value: selectedProviderCategory?.value
    }
  ]);

  useEffect(() => {
    if (
      selectedState.value !== null &&
      selectedProviderCategory.value !== null
    ) {
      refetch();
      setRowData([]);
      setLoading(true);
    }
  }, [selectedState, selectedProviderCategory]);

  useEffect(() => {
    if (data && data.length > 0) {
      setRowData(data);
      setLoading(false);
    }
  }, [data]);

  function ratioToPercentFormatter(params: ValueFormatterParams) {
    if (!params.value || params.value === 0) return '';
    return '' + Math.round(params.value * 100) + '%';
  }
  const columnDefs = [
    {
      headerName: 'Provider County',
      field: 'main_individual_provider_computed_county',
      rowGroup: true,
      hide: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Hospital Affiliation',
      field: 'hos_aff',
      rowGroup: true,
      hide: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Office Affiliation',
      field: 'off_aff',
      rowGroup: true,
      hide: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Provider Speciality',
      field: 'main_individual_provider_primary_specialty',
      rowGroup: true,
      hide: true,
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Provider Name',
      field: 'main_individual_provider_name',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Number of Patients',
      field: 'num_patients',
      aggFunc: 'sum',
      valueFormatter: v => formatNumberToHaveCommas(v.value),
      cellStyle: { textAlign: 'right' },
      sort: 'desc'
    },
    {
      headerName: 'Number of Beneficiaries',
      field: 'tot_benes',
      aggFunc: 'sum',
      valueFormatter: v => formatNumberToHaveCommas(v.value),
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Apcd to CMS Ratio',
      valueGetter: params => {
        if (!params.node.group) {
          return {
            num_patients: params.data.num_patients,
            tot_benes: params.data.tot_benes,
            toString: () =>
              params.data.num_patients && params.data.tot_benes
                ? params.data.num_patients / params.data.tot_benes
                : 0
          };
        }
      },
      aggFunc: params => {
        let patientsSum = 0;
        let beneSum = 0;
        params.values.forEach(value => {
          if (value && value.num_patients) {
            patientsSum += value.num_patients;
          }
          if (value && value.tot_benes) {
            beneSum += value.tot_benes;
          }
        });
        return {
          num_patients: patientsSum,
          tot_benes: beneSum,
          toString: () => {
            return `${patientsSum && beneSum ? patientsSum / beneSum : 0}`;
          }
        };
      },
      valueFormatter: ratioToPercentFormatter,
      cellStyle: { textAlign: 'right' }
    }
  ];
  const autoSizeStrategy = {
    type: 'fitCellContents'
  } as SizeColumnsToContentStrategy;

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  const autoGroupColumnDef = {
    headerName: 'County > Affiliation > Speciality > Name',
    minWidth: 220,
    filter: 'agGroupColumnFilter'
  };

  return (
    <div style={{ width: '100%', marginBottom: '300px' }}>
      <div
        style={{
          display: 'flex',
          marginBottom: '10px',
          justifyContent: 'center'
        }}
      >
        <div style={{ display: 'flex', flex: 1, marginRight: '10px' }}>
          <label
            id="state-dropdown-label"
            style={{ flex: 1, textAlign: 'center' }}
          >
            State:
          </label>
          <OpensearchGenericTypeahead
            id="state-dropdown"
            database="raw"
            schema="prod_apcd"
            table_name="patient_claim_detail_zip5_view"
            placeholder="Select State"
            columnNames="main_individual_provider_computed_state"
            value={selectedState}
            onChange={handleStateChange}
            aria-labelledby="state-dropdown-label"
          />
        </div>
        <div style={{ display: 'flex', flex: 1, marginRight: '10px' }}>
          <label
            id="provider-category-dropdown-label"
            style={{ flex: 1, textAlign: 'center' }}
          >
            Provider Category:
          </label>
          <OpensearchGenericTypeahead
            id="provider-category-dropdown"
            database="raw"
            schema="prod_apcd"
            table_name="patient_claim_detail_zip5_view"
            placeholder="Select Provider Category"
            columnNames="main_individual_provider_category"
            value={selectedProviderCategory}
            onChange={handleProviderCategoryChange}
            aria-labelledby="provider-category-dropdown-label"
          />
        </div>
      </div>
      {selectedState.value && selectedProviderCategory.value && (
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            ref={gridApiRef}
            rowData={rowData}
            columnDefs={columnDefs as ColDef[]}
            autoGroupColumnDef={autoGroupColumnDef}
            domLayout="autoHeight"
            suppressAggFuncInHeader={true}
            onGridReady={onGridReady}
            autoSizeStrategy={autoSizeStrategy}
            loading={loading}
            loadingOverlayComponent={() => (
              <FontAwesomeIcon icon={faSpinner as IconProp} spin size="lg" />
            )}
          />
        </div>
      )}
    </div>
  );
};

export const useGetApcdCoverageMetricsByState = filters => {
  let enabled = false;
  if (filters) {
    enabled = filters.every(
      filter => filter.value !== null && filter.value !== undefined
    );
  }
  return useQuery({
    queryKey: ['account', 'apcd_coverage_metrics_by_state', filters],
    queryFn: () => getApcdCoverageMetricsByState(filters, enabled),
    enabled: enabled // Enable query only if all filters have values
  });
};

export const getApcdCoverageMetricsByState = async (filters, enabled) => {
  const params = {};
  if (filters) {
    filters.forEach(filter => {
      if (!params[filter.column]) {
        params[filter.column] = [];
      }
      if (Array.isArray(filter.value)) {
        params[filter.column].push(...filter.value);
      } else {
        params[filter.column].push(filter.value);
      }
    });
  }
  if (enabled) {
    const res = await accountApi.get<ApcdCoverageMetricsResponse>(
      `dataset/apcd_coverage_metrics_by_state/`,
      { params: params }
    );
    if (!res) {
      throw Error('Account API failed to respond. Please try again.');
    }
    handleServiceUnavailable(res);

    return res.data.coverage_metrics;
  } else {
    return [];
  }
};
