import Backbone from 'backbone';

let Stratasan = Stratasan || {
  Models: {},
  Collections: {},
  Views: {},
  App: {}
};

Stratasan.Models.Zipcode = Backbone.Model.extend({
  defaults: {
    order: 999,
    collection: 'primary',
    population: 'N/A',
    market_share: 0,
    cases: 'N/A',
    csum: 'N/A',
    share_of_patients: 0,
    total_cases: 'N/A'
  },

  updateCollection: function() {
    let model = this;
    let collection;
    let postal_code_children;
    let zipcode_obj;
    let collections = ['primary', 'secondary', 'other'];
    collection = model.get('collection');
    let index = collections.indexOf(collection);
    let new_collection = collections[(index + 1) % collections.length];
    // Set any child zips in the same as their parent first
    postal_code_children = model.attributes.postal_codes;
    if (postal_code_children.length) {
      postal_code_children.forEach(function(po) {
        zipcode_obj = model.collection.findWhere({ zipcode: po });
        if (typeof zipcode_obj !== 'undefined') {
          zipcode_obj.set('collection', new_collection);
        }
      });
    }
    model.set('collection', new_collection);
  }
});

export const Zipcode = Stratasan.Models.Zipcode;
