const templateContainer = $('#service-areas-container');

if (templateContainer.length) {
  if ($('.form-group').hasClass('has-error')) {
    $('#create-service-area').modal('show');
  }

  const submitDeleteForm = e => {
    e.preventDefault();
    const confirmed = confirm(
      'Are you sure you want to delete this Service Area?'
    );
    const form = $(e.target)
      .parent()
      .siblings('form')
      .first();
    if (confirmed) {
      form.submit();
    }
  };

  $('.submit-delete-form-btn').on('click', submitDeleteForm);
}
