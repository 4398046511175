import { debounce } from 'lodash';
import DOMPurify from 'dompurify';
import typeahead from 'typeahead';

const container = $('#explorer-slice-request');

const clearAndFocus = evt => {
  evt.target.value = '';
  evt.target.focus();
};

if (container.length) {
  const $autocompleteProvider = container.find('input.autocomplete-provider');

  if ($autocompleteProvider.length) {
    $autocompleteProvider.on('change', clearAndFocus);
    const autocompleteFieldName = $autocompleteProvider.data('field-name');
    let autocompleteResults = [];
    let getAPIResultsProvider = (term, result) => {
      const dataset_id = $('#id_dataset').val();

      if (dataset_id) {
        $.getJSON(
          `/api/pathways/state_facilities/${dataset_id}/`,
          { query: term, perPage: 500 },
          response => {
            autocompleteResults = response.results.map(facility => ({
              value: facility.facility_id,
              label: facility.name
            }));
            result(autocompleteResults.map(item => item.label));
          }
        );
      }
    };

    typeahead($autocompleteProvider[0], {
      minLength: 3,
      source: debounce(getAPIResultsProvider, 300),
      updater: label => {
        if (label) {
          for (let key = 0; key < autocompleteResults.length; key++) {
            const result = autocompleteResults[key];

            if (result.label === label) {
              const $groupName = $('#id_group_name');
              const sanitizedSelectedProvider = DOMPurify.sanitize(`
                <div class="selected-provider">
                  <input type="hidden" name="${autocompleteFieldName}" value="${result.value}" />
                  ${result.label} <a href="#" class="remove-provider">Remove</a>
                </div>
              `);
              $autocompleteProvider
                .siblings('div.selected-provider')
                .append(sanitizedSelectedProvider);

              if ($groupName.length) {
                if ($groupName.val() === '') {
                  $groupName.val(result.value);
                }

                if (
                  $autocompleteProvider
                    .siblings('div.selected-provider')
                    .children().length > 1
                ) {
                  $groupName.parents('.form-group').removeClass('hide');
                }
              }

              break;
            }
          }
        }

        return label;
      }
    });

    $('.selected-provider').on('click', '.remove-provider', function(event) {
      $(this)
        .parent('div.selected-provider')
        .remove();
      event.preventDefault();
    });
  }
}
