export function ValidationCellEditor() {}

//this regex checks for alphanumeric values
const procedureCodeRegEx = new RegExp(/^[a-zA-Z0-9]*$/);

ValidationCellEditor.prototype.init = function(params) {
  this.isValid = true;

  this.eGui = document.createElement('div');
  this.eGui.innerHTML = `
    <input class='ag-input-field-input' value=${params.value} />
  `;
  this.eInput = this.eGui.querySelector('input');
  this.eInput.addEventListener('input', this.inputChanged.bind(this));
};

ValidationCellEditor.prototype.inputChanged = function(event) {
  if (this.validate(event.target.value)) {
    this.isValid = true;
    this.eGui.classList.remove('red-bg');
  } else {
    this.isValid = false;
    this.eGui.classList.add('red-bg');
  }
};

ValidationCellEditor.prototype.validate = function(value) {
  return value.length >= 3 && procedureCodeRegEx.test(value);
};

ValidationCellEditor.prototype.isCancelAfterEnd = function() {
  return !this.isValid;
};

ValidationCellEditor.prototype.getValue = function() {
  return this.eInput.value;
};

ValidationCellEditor.prototype.getGui = function() {
  return this.eGui;
};

ValidationCellEditor.prototype.destroy = function() {
  this.eInput.removeEventListener('input', this.inputChanged);
};
