const userManagementContainer = $('#user-management-container');

if (userManagementContainer.length) {
  $(function() {
    $('[data-toggle="tooltip"]').tooltip();

    $('.select-all-checkbox').on('click', function() {
      var table = $(this).closest('table');
      var items = $('input[name=id]', table);
      var checked = $(this).prop('checked');

      items.each(function(i, item) {
        $(item).prop('checked', checked);
      });
    });

    $('#users-deactivate-btn').on('click', function() {
      confirm('Are you sure you want to deactivate the selected users?');
      document.getElementById('users-deactivate').submit();
    });

    $('#user-revoke-linked-access-btn').on('click', function() {
      confirm('Are you sure you want to revoke access of the selected users?');
      document.getElementById('user-revoke-linked-access').submit();
    });

    $('#users-activate-btn').on('click', function() {
      confirm('Are you sure you want to activate the selected users?');
      document.getElementById('users-activate').submit();
    });
  });
}
