import { View } from 'backbone';

let StrataMapSearchControl = View.extend({
  initialize: function(options) {
    this.parent = options.parent;
  },

  el: $('#options_modal'),

  events: {
    'change #search_radius': 'searchUpdate',
    'change #run_search': 'searchUpdate'
  },

  searchUpdate: function() {
    let view = this;
    let radius = 0;

    view.updateResultsText('');
    if (view.searchIsActivated()) {
      view.updateResultsText('Searching...');
      view.getURL(url => {
        let getOpts = view.getOpts();
        $.get(url, getOpts, view.handleSearchResponse.bind(view));
      });
    }
  },

  handleSearchResponse: function(response) {
    // other search views can override
    let view = this;
    let results = response.results,
      radius = response.radius,
      lat = response.lat,
      lng = response.lng;
    let lnglat = [lng, lat];

    view.parent.placeHospitals(results);
    view.parent.placeSearchRadiusRing(radius, lnglat);

    let content =
      'Found ' +
      results.length +
      ' hospital(s) within ' +
      radius +
      ' miles including the chosen facility.';
    view.updateResultsText(content);
  },
  getURL: function(cb) {
    // Other search views can override
    const radius = this.getSearchRadius();
    const auth = this.parent.getMappingAuth();

    this.parent.getSearchLngLat(lnglat => {
      let base_url = `/mapping/nearby_facilities/${lnglat[1]}/${lnglat[0]}/${radius}/`;

      if ('fsa' in auth && 'token' in auth) {
        cb(`${base_url}${auth.fsa}/${auth.token}`);
      } else {
        cb(base_url);
      }
    });
  },

  getOpts: function() {
    // Other search views can override
    return {};
  },

  updateResultsText: function(content) {
    $('#search_results')[0].textContent = content;
  },

  searchIsActivated: function() {
    return $('#run_search:checked').length > 0;
  },

  getSearchRadius: function() {
    return $('#search_radius').val();
  },

  getSearchType: function() {
    // Update when we implement new types of search
    return 'radius';
  },

  disableSearch: function() {
    $('#run_search').removeAttr('checked');
    this.updateResultsText('');
  },
  setSearchRadius: function(radius) {
    $('#search_radius option').removeAttr('selected');
    $(`#search_radius option[value="${radius}"]`).prop('selected', true);
  }
});

export default StrataMapSearchControl;
