import { QueryClientProvider } from 'react-query';
import { ApcdTypeOneTwoCoverageMetrics } from './ApcdTypeOneTwoCoverageMetrics';
import { queryClient } from './libs/api';
import React from 'react';

export const ApcdCoverageMetrics: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApcdTypeOneTwoCoverageMetrics />
    </QueryClientProvider>
  );
};
