import StrataBaseMap from './base';
import MapOptionsControl from '../controls/MapOptionsControl';
import LegendControl from '../controls/LegendControl';
import InfoControl from '../controls/InfoControl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.min';
import circle from '@turf/circle';

let StrataFacilityMap = StrataBaseMap.extend({
  setupControls: function() {
    let view = this;

    const { 'service-areas': legendItems } = view.styles;
    const legendStyle = {};
    Object.keys(legendItems).forEach(key => {
      if (key !== 'other') {
        legendStyle[key] = legendItems[key];
      }
    });
    view.map.addControl(
      new LegendControl({ title: 'Service Area', style: legendStyle })
    );
    view.infoControl = new InfoControl();
    view.map.addControl(view.infoControl);
    view.map.addControl(
      new MapOptionsControl({
        shouldDisplayControls: view.shouldDisplayControls()
      })
    );
  },

  placeSearchRadiusRing: function(radius, lngLat) {
    const circleGeoJSON = {
      type: 'FeatureCollection',
      features: [circle(lngLat, radius, { units: 'miles' })]
    };

    this.addOrUpdateLayer('search-ring', circleGeoJSON);
  },

  getHospitalLngLat: function(cb) {
    let view = this;
    if ('lat' in view.hospital && 'long' in view.hospital) {
      return cb
        ? cb([view.hospital.long, view.hospital.lat])
        : [view.hospital.long, view.hospital.lat];
    }

    if ('coordinates' in view.hospital) {
      return cb ? cb(view.hospital.coordinates) : view.hospital.coordinates;
    }

    view
      .geocode(view.hospital.zipcode)
      .then(response => response.json())
      .then(geojson => {
        if ('features' in geojson) {
          view.hospital.coordinates = geojson.features[0].geometry.coordinates;
          return cb ? cb(view.hospital.coordinates) : view.hospital.coordinates;
        }
        return cb ? cb([]) : [];
      });
  },

  placeHospitals: function(otherHospitals) {
    const otherHospitalFeatures = otherHospitals.map(hospital => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [hospital.long, hospital.lat]
        },
        properties: {
          name: hospital.name,
          collection: 'other'
        }
      };
    });

    this.getHospitalLngLat(lngLat => {
      const primaryHospital = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: lngLat
        },
        properties: {
          name: this.hospital.name,
          collection: 'primary'
        }
      };

      const hospitalGeoJSON = {
        type: 'FeatureCollection',
        features: [...otherHospitalFeatures, primaryHospital]
      };

      this.addOrUpdateLayer('hospitals', hospitalGeoJSON);
    });
  },

  getMappingAuth: function() {
    return {};
  },

  geocode: function(input) {
    const GEOCODE_BASE_URL = `https://api.mapbox.com/geocoding/v5/mapbox.places`;
    return fetch(
      `${GEOCODE_BASE_URL}/${input}.json?types=postcode&&country=us&access_token=${this.accessToken}`
    );
  }
});

export default StrataFacilityMap;
