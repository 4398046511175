const templateContainer = $('#data-set-notifications-container');

if (templateContainer.length) {
  const toggleDataNotificationList = () => {
    if (
      $('input[name=data-notification]:checked').val() === 'notify-all-data'
    ) {
      $('#data-notification-well').addClass('hide');
    } else {
      $('#data-notification-well').removeClass('hide');
    }
  };

  $('input[name=data-notification]').click(function() {
    toggleDataNotificationList();
  });

  toggleDataNotificationList();

  $('input[name=select-all]', 'table').click(function() {
    var table = $($(this).parents('table'));
    var items = $('input[name=id]', table);
    var checked = $(this).prop('checked');
    for (var i = 0; i < items.length; i++) {
      $(items[i]).prop('checked', checked);
    }
  });
}
