const templateContainer = $('.about-dataset-controller');

if (templateContainer.length) {
  $('#create-curated-query-btn').on('click', function() {
    $('#create-query-form').submit();
  });
  $('#create-advanced-query-btn').on('click', function() {
    $('#create-query-form input[name="advanced"]').val(true);
    $('#create-query-form').submit();
  });

  $('#edit-notes').hide();
  $('#edit-notes-button').on('click', function() {
    $('#edit-notes')
      .show()
      .find('textarea')
      .css('height', $('#view-notes').css('height'));

    $('#view-notes').hide();
    $('#edit-notes-button').hide();
  });

  $('#cancel-edit-button').on('click', function() {
    $('#edit-notes').hide();
    $('#view-notes').show();
    $('#edit-notes-button').show();
  });
}
