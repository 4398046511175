const templateContainer = $('#service-area-detail-container');

if (templateContainer.length) {
  const checkboxesSelector = 'input[name=id]';
  const checkedBoxesSelector = `${checkboxesSelector}:checked`;
  let removeBtn = $('#remove-btn');

  const moveToUpdater = function() {
    let numChecked = $(checkedBoxesSelector).length;
    removeBtn.addClass('disabled');

    removeBtn.off('click', submitRemoveUnitsForm);
    if (numChecked > 0) {
      removeBtn.removeClass('disabled');
      removeBtn.on('click', submitRemoveUnitsForm);
    } else {
      removeBtn.addClass('disabled');
    }
  };

  // Make sure page begins in the correct state
  moveToUpdater();

  $('input[type=checkbox]', 'table').on('change', moveToUpdater);

  $('.move-to-selector').on('click', function() {
    let moveto = this.id;
    $('<input />')
      .attr('type', 'hidden')
      .attr('name', 'destination')
      .attr('value', moveto)
      .appendTo('#move-units-form');
    $(checkedBoxesSelector).each(function(index, input) {
      $('#move-units-form').append(input);
    });
    $(checkedBoxesSelector).hide();
    $('#move-units-form').submit();
  });

  $('#id_service_area').change(function() {
    if ($('#id_service_area').val() === 'custom') {
      $('input[name="county_search"]')
        .parents('div.form-group')
        .removeClass('hide');
    } else {
      $('input[name="county_search"]')
        .parents('div.form-group')
        .addClass('hide');
    }
  });

  $('#id_service_area').trigger('change');

  if ($('.form-group').hasClass('has-error')) {
    $('#add-sa-modal').modal('show');
  }

  const submitRemoveUnitsForm = e => {
    e.preventDefault();
    let confirmed = confirm(
      'Are you sure you want to remove the selected regions?'
    );
    if (confirmed) {
      $('input[name="id"]:checked').hide();
      $('input[name="id"]:checked').each(function(index, input) {
        $('#row_' + input.value).hide();
        $('#remove-units-form').append(input);
      });
      $('form#remove-units-form').submit();
    }

    const handleOnSelect = evt => {
      evt.target.value = '';
      evt.target.focus();
    };

    $('input.autocomplete-county').on('change', handleOnSelect);
  };

  if ($('#sag-name-form .form-group').hasClass('has-error')) {
    $('#sag-name-form')
      .addClass('active')
      .find('#id_name')
      .focus();
    $('#sag-name').hide();
  }

  $('#sag-name').on('click', function() {
    $('#sag-name').hide();
    $('#sag-name-form')
      .addClass('active')
      .find('#id_name')
      .focus();
  });
  $('#sag-name-form').on('submit', function() {
    $('#sag-name-form').removeClass('active');
    $('#sag-name').show();
  });
  if ($('#sag-name-form').hasClass('has-error')) {
    $('#sag-name').hide();
    $('#sag-name-form')
      .addClass('active')
      .find('#id_name')
      .focus();
  }
}
