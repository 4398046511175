const templateContainer = $('#group-container');

if (templateContainer.length) {
  (function($) {
    $.fn.liveDraggable = function(opts) {
      this.on('mouseover', function() {
        if (!$(this).data('init')) {
          $(this)
            .data('init', true)
            .draggable(opts);
        }
      });
    };
  })(jQuery);
  $(function() {
    var add_url =
      "{% url 'group-modify-membership' group_id=group.id action='add' %}";
    var remove_url =
      "{% url 'group-modify-membership' group_id=group.id action='remove' %}";

    function add_member(user_id) {
      $.ajax({
        url: add_url,
        type: 'POST',
        data: { id: user_id },
        async: false,
        success: () => void null,
        error: function(jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText);
          console.log(textStatus);
          console.log(errorThrown);
        }
      });
      return true;
    }
    function remove_member(user_id) {
      $.ajax({
        url: remove_url,
        type: 'POST',
        data: { id: user_id },
        success: () => alert('member removed')
      });
    }
    $('.member').liveDraggable({
      appendTo: 'body',
      cursor: 'move',
      helper: 'clone'
    });
    $('#current').droppable({
      accept: 'li.available',
      activeClass: 'ui-state-hover',
      hoverClass: 'ui-state-active',
      drop: function(event, ui) {
        var user_id = ui.draggable.attr('id');
        var result = add_member(user_id);
        if (result) {
          $(this)
            .find('.placeholder')
            .remove();
          $("<li class='member current' id='" + user_id + "'></li>")
            .text(ui.draggable.text())
            .appendTo(this);
          remove_available(ui.draggable);
        }
      }
    });
    $('#available').droppable({
      accept: 'li.current',
      activeClass: 'ui-state-hover',
      hoverClass: 'ui-state-active',
      drop: function(event, ui) {
        var user_id = ui.draggable.attr('id');
        $("<li class='member available' id='" + user_id + "'></li>")
          .text(ui.draggable.text())
          .appendTo(this);
        remove_current(ui.draggable);
        remove_member(user_id);
      }
    });
    function remove_available($item) {
      $item.fadeOut();
    }
    function remove_current($item) {
      $item.fadeOut();
    }

    $('.chosen-select').chosen();
  });
}
