export default class Legend {
  constructor(options) {
    this.options = options;
  }

  onAdd(map) {
    const { title, style } = this.options;
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl map-legend';

    const titleElement = document.createElement('p');
    titleElement.innerText = title;
    this._container.appendChild(titleElement);

    Object.keys(style).forEach(key => {
      const legendItem = document.createElement('div');
      const content = `<span style='background-color: ${
        style[key]
      }'></span>${this.titleCase(key)}`;
      legendItem.innerHTML = content;
      this._container.appendChild(legendItem);
    });

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  getDefaultPosition() {
    return 'bottom-right';
  }

  titleCase(s) {
    return s
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
