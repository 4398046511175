// This is a handy function taken from Django docs to get
// cookie values by name. It is primarily used to grab the
// CSRF token and inject it into the POST payload.
export const getCookie = (name: string) => {
  let cookieValue: any = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let index = 0; index < cookies.length; index++) {
      const cookie = cookies[index].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};
