import { debounce } from 'lodash';
import typeahead from 'typeahead';
import 'typeahead/style.css';

const $autocompleteCounty = $('input.autocomplete-county');

if ($autocompleteCounty.length) {
  const clearAndFocus = evt => {
    evt.target.value = '';
    evt.target.focus();
  };

  $autocompleteCounty.on('change', clearAndFocus);

  const fieldName = $autocompleteCounty.data('field-name');
  let results = [];
  let getAPIResults = (term, result) => {
    $.getJSON('/reports/geo_autocomplete/county', { term }, items => {
      results = items;
      result(items.map(item => item.label));
    });
  };

  typeahead($autocompleteCounty[0], {
    minLength: 3,
    delay: 500,
    source: debounce(getAPIResults, 300),
    updater: label => {
      if (label) {
        for (let key = 0; key < results.length; key++) {
          const result = results[key];

          if (result.label === label) {
            const selectedCounty = document.createElement('div');
            selectedCounty.classList.add('selected-county');
            const countyInput = document.createElement('input');
            countyInput.type = 'hidden';
            countyInput.setAttribute('name', fieldName);
            countyInput.setAttribute('value', result.value);
            selectedCounty.appendChild(countyInput);
            const removeCounty = document.createElement('a');
            removeCounty.href = '#';
            removeCounty.classList.add('remove-county');
            removeCounty.textContent = ' Remove';
            selectedCounty.appendChild(document.createTextNode(result.label));
            selectedCounty.appendChild(removeCounty);
            document.getElementById('selected-counties').append(selectedCounty);
            break;
          }
        }
      }

      return label;
    }
  });

  $('#selected-counties').on('click', '.remove-county', function(event) {
    $(this)
      .parent('div.selected-county')
      .remove();
    event.preventDefault();
  });
}
