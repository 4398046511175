export default class InfoControl {
  constructor(options) {
    this.options = options;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl info-control hide';

    return this._container;
  }

  updateInfo(text) {
    this._container.className = `mapboxgl-ctrl info-control`;
    this._container.innerText = text;
  }

  clearInfo() {
    this._container.className = 'mapboxgl-ctrl info-control hide';
    this._container.innerText = '';
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  getDefaultPosition() {
    return 'top-right';
  }
}
