const templateContainer = $('#product-lines-container');

if (templateContainer.length) {
  const submitDeleteForm = e => {
    e.preventDefault();
    const confirmed = confirm(
      'Are you sure you want to delete this Product Line?'
    );
    const form = $(e.target)
      .parent()
      .siblings('form')
      .first();
    if (confirmed) {
      form.submit();
    }
  };

  $('.submit-delete-form-btn').on('click', submitDeleteForm);
}
