import { QueryClient } from 'react-query';
import axios from 'axios';
import { getCookie } from './djangoCookie';

// React Query Setup
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity, useErrorBoundary: true },
    mutations: { useErrorBoundary: true }
  }
});

// Axios Setup
const accountApi = axios.create({
  baseURL: '/account/api/',
  headers: {
    'X-CSRFToken': getCookie('csrftoken')
  }
});

// Redirect users to login when API calls start getting 403s
accountApi.interceptors.response.use(
  res => res,
  error => {
    if (error?.res?.status === 403) {
      window.location.replace(`/?next=${window.location.pathname}`);
    }
  }
);
export { accountApi };

export function handleServiceUnavailable(res) {
  if (!res.data) {
    throw Error('Something has gone wrong');
  }
  if (res.data.status && res.data.status == 503) {
    throw Error(res.data['detail']);
  }
}
