export default class MapOptions {
  constructor(props) {
    this.shouldDisplayControls = props.shouldDisplayControls;
  }
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl';
    //#options-content is a hidden div set in the django template
    this._container.innerHTML = document.getElementById(
      'options-content'
    ).innerHTML;
    if (!this.shouldDisplayControls) {
      this._container.classList.add('hide');
    }
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  getDefaultPosition() {
    return 'bottom-left';
  }
}
