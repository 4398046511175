const container = $('.dashboard-top');

if (container.length) {
  $('#info-gallery').tooltip({
    placement: 'bottom',
    html: true,
    title: 'A curated list of reports,<br/>results and assets.'
  });
  $('#info-canvas').tooltip({
    placement: 'bottom',
    html: true,
    title: 'Gather intelligence from<br/>customized reports.'
  });
  $('#info-blackbird').tooltip({
    placement: 'bottom',
    html: true,
    title: 'Explore data with our<br/>adhoc query tool.'
  });
  $('#info-history').tooltip({
    placement: 'left',
    html: true,
    title:
      'These are the most recent updates<br/>to Market Query data sets that<br/>you have access to.'
  });
}
