import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './stylesheets/main.scss';

import '../../assets/lib/tablesorter';
import '../../assets/lib/autocompleteCounty';
import './scripts/serviceAreaDetail';
import './scripts/serviceAreas';
import './scripts/aboutDataset';
import './scripts/productLines';
import './scripts/productLineDefinitionDetail';
import './scripts/dataSetNotifications';
import './scripts/group';
import './scripts/help';
import './scripts/fsaMap';
import './scripts/user';
import './scripts/dashboard';
import './scripts/registration/login';

// Temporary Explorer
import 'chosen-npm/public/chosen.jquery.js';
import './scripts/explorerSliceCreate';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';

import React from 'react';
import { render } from 'react-dom';

LicenseManager.setLicenseKey(window['CONFIGURATION'].AG_GRID_LICENSE_KEY);
window['LicenseManager'] = LicenseManager;

import { RateCrosswalkDetail } from './App';
import { ApcdCoverageMetrics } from './ApcdCoverageMetrics';
const domElement = document.getElementById('rate-crosswalk-detail');
if (domElement) {
  render(<RateCrosswalkDetail />, domElement);
}

const domApcdCoverageMetricsElement = document.getElementById(
  'apcd-coverage-metrics'
);
if (domApcdCoverageMetricsElement) {
  render(<ApcdCoverageMetrics />, domApcdCoverageMetricsElement);
}

$('.chosen-select').chosen();
