const templateContainer = $('#help-container');

if (templateContainer.length) {
  $(function() {
    $('#admin-help-edit').click(function(e) {
      e.preventDefault();
      swapButtons();
    });
  });

  const swapButtons = () => {
    $('#admin-help-body').toggleClass('hide');
    $('#admin-help-form-col').toggleClass('hide');
    $('#admin-help-edit').toggleClass('hide');
  };
}
